import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step crypto jwk create`}</strong>{` -- create a JWK (JSON Web Key)`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step crypto jwk create <public-jwk-file> <private-jwk-file>
[--kty=<type>] [--alg=<algorithm>] [--use=<use>]
[--size=<size>] [--crv=<curve>] [--kid=<kid>]
[--from-pem=<pem-file>] [--password-file=<file>]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step crypto jwk create`}</strong>{` generates a new JWK (JSON Web Key) or constructs a
JWK from an existing key. The generated JWK conforms to RFC7517 and can be used
to sign and encrypt data using JWT, JWS, and JWE.`}</p>
    <p>{`Files containing private keys are encrypted by default. You'll be prompted for
a password. Keys are written with file mode `}<strong parentName="p">{`0600`}</strong>{` (i.e., readable and
writable only by the current user).`}</p>
    <p>{`All flags are optional. Defaults are suitable for most use cases.`}</p>
    <h2>{`Positional arguments`}</h2>
    <p><inlineCode parentName="p">{`public-jwk-file`}</inlineCode>{`
Path to which the public JWK should be written`}</p>
    <p><inlineCode parentName="p">{`private-jwk-file`}</inlineCode>{`
Path to which the (JWE encrypted) private JWK should be written`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--kty`}</strong>{`=`}<inlineCode parentName="p">{`type`}</inlineCode>{`, `}<strong parentName="p">{`--type`}</strong>{`=`}<inlineCode parentName="p">{`type`}</inlineCode>{`
The `}<inlineCode parentName="p">{`type`}</inlineCode>{` of key to create. Corresponds to the `}<strong parentName="p">{`"kty"`}</strong>{` JWK parameter.
If unset, default is EC.`}</p>
    <p><inlineCode parentName="p">{`type`}</inlineCode>{` is a case-sensitive string and must be one of:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`EC`}</strong>{`: Create an `}<strong parentName="p">{`elliptic curve`}</strong>{` keypair`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`oct`}</strong>{`: Create a `}<strong parentName="p">{`symmetric key`}</strong>{` (octet stream)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`OKP`}</strong>{`: Create an octet key pair (for `}<strong parentName="p">{`"Ed25519"`}</strong>{` curve)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`RSA`}</strong>{`: Create an `}<strong parentName="p">{`RSA`}</strong>{` keypair`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`--size`}</strong>{`=`}<inlineCode parentName="p">{`size`}</inlineCode>{`
The `}<inlineCode parentName="p">{`size`}</inlineCode>{` (in bits) of the key for RSA and oct key types. RSA keys require a
minimum key size of 2048 bits. If unset, default is 2048 bits for RSA keys and 128 bits for oct keys.`}</p>
    <p><strong parentName="p">{`--crv`}</strong>{`=`}<inlineCode parentName="p">{`curve`}</inlineCode>{`, `}<strong parentName="p">{`--curve`}</strong>{`=`}<inlineCode parentName="p">{`curve`}</inlineCode>{`
The elliptic `}<inlineCode parentName="p">{`curve`}</inlineCode>{` to use for EC and OKP key types. Corresponds
to the `}<strong parentName="p">{`"crv"`}</strong>{` JWK parameter. Valid curves are defined in JWA `}{`[RFC7518]`}{`. If
unset, default is P-256 for EC keys and Ed25519 for OKP keys.`}</p>
    <p><inlineCode parentName="p">{`curve`}</inlineCode>{` is a case-sensitive string and must be one of:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`P-256`}</strong>{`: NIST P-256 Curve`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`P-384`}</strong>{`: NIST P-384 Curve`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`P-521`}</strong>{`: NIST P-521 Curve`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Ed25519`}</strong>{`: Ed25519 Curve`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`--alg`}</strong>{`=`}<inlineCode parentName="p">{`algorithm`}</inlineCode>{`, `}<strong parentName="p">{`--algorithm`}</strong>{`=`}<inlineCode parentName="p">{`algorithm`}</inlineCode>{`
The `}<inlineCode parentName="p">{`algorithm`}</inlineCode>{` intended for use with this key. Corresponds to the
`}<strong parentName="p">{`"alg"`}</strong>{` JWK parameter. `}<inlineCode parentName="p">{`algorithm`}</inlineCode>{` is case-sensitive. If unset, the default
depends on the key use, key type, and curve (for EC and OKP keys). Defaults are:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`key type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`use`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`curve`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`default algorithm`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`EC`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`sig`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`P-256`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ES256`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`EC`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`sig`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`P-384`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ES384`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`EC`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`sig`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`P-521`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ES512`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`oct`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`sig`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HS256`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`RSA`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`sig`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`RS256`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`OKP`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`sig`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Ed25519`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`EdDSA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`EC`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`enc`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`P-256`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ECDH-ES`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`EC`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`enc`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`P-384`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ECDH-ES`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`EC`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`enc`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`P-521`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ECDH-ES`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`oct`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`enc`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A256GCMKW`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`RSA`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`enc`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`N/A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`RSA-OAP-256`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`If the key `}<strong parentName="p">{`"use"`}</strong>{` is `}<strong parentName="p">{`"sig"`}</strong>{` (signing) `}<inlineCode parentName="p">{`algorithm`}</inlineCode>{` must be one of:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`HS256`}</strong>{`: HMAC using SHA-256`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`HS384`}</strong>{`: HMAC using SHA-384`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`HS512`}</strong>{`: HMAC using SHA-512`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`RS256`}</strong>{`: RSASSA-PKCS1-v1_5 using SHA-256`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`RS384`}</strong>{`: RSASSA-PKCS1-v1_5 using SHA-384`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`RS512`}</strong>{`: RSASSA-PKCS1-v1_5 using SHA-512`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`ES256`}</strong>{`: ECDSA using P-256 and SHA-256`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`ES384`}</strong>{`: ECDSA using P-384 and SHA-384`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`ES512`}</strong>{`: ECDSA using P-521 and SHA-512`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`PS256`}</strong>{`: RSASSA-PSS using SHA-256 and MGF1 with SHA-256`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`PS384`}</strong>{`: RSASSA-PSS using SHA-384 and MGF1 with SHA-384`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`PS512`}</strong>{`: RSASSA-PSS using SHA-512 and MGF1 with SHA-512`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`EdDSA`}</strong>{`: EdDSA signature algorithm`}</p>
      </li>
    </ul>
    <p>{`If the key `}<strong parentName="p">{`"use"`}</strong>{` is `}<strong parentName="p">{`"enc"`}</strong>{` (encryption) `}<inlineCode parentName="p">{`algorithm`}</inlineCode>{` must be one of:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`RSA1_5`}</strong>{`: RSAES-PKCS1-v1_5`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`RSA-OAEP`}</strong>{`: RSAES OAEP using default parameters`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`RSA-OAEP-256`}</strong>{`: RSAES OAEP using SHA-256 and MGF1 with SHA-256`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`A128KW`}</strong>{`: AES Key Wrap with default initial value using 128-bit key`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`A192KW`}</strong>{`: AES Key Wrap with default initial value using 192-bit key`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`A256KW`}</strong>{`: AES Key Wrap with default initial value using 256-bit key`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`dir`}</strong>{`: Direct use of a shared symmetric key as the content encryption key (CEK)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`ECDH-ES`}</strong>{`: Elliptic Curve Diffie-Hellman Ephemeral Static key agreement`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`ECDH-ES+A128KW`}</strong>{`: ECDH-ES using Concat KDF and CEK wrapped with "A128KW"`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`ECDH-ES+A192KW`}</strong>{`: ECDH-ES using Concat KDF and CEK wrapped with "A192KW"`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`ECDH-ES+A256KW`}</strong>{`: ECDH-ES using Concat KDF and CEK wrapped with "A256KW"`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`A128GCMKW`}</strong>{`: Key wrapping with AES GCM using 128-bit key`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`A192GCMKW`}</strong>{`: Key wrapping with AES GCM using 192-bit key`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`A256GCMKW`}</strong>{`: Key wrapping with AES GCM using 256-bit key`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`PBES2-HS256+A128KW`}</strong>{`: PBES2 with HMAC SHA-256 and "A128KW" wrapping`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`PBES2-HS384+A192KW`}</strong>{`: PBES2 with HMAC SHA-256 and "A192KW" wrapping`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`PBES2-HS512+A256KW`}</strong>{`: PBES2 with HMAC SHA-256 and "A256KW" wrapping`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`--use`}</strong>{`=`}<inlineCode parentName="p">{`use`}</inlineCode>{`
The intended `}<inlineCode parentName="p">{`use`}</inlineCode>{` of the public key. Corresponds to the "use" JWK parameter.
The "use" parameter indicates whether the public key is used for encrypting
data or verifying the signature on data.`}</p>
    <p><inlineCode parentName="p">{`use`}</inlineCode>{` is a case-sensitive string and may be one of:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`sig`}</strong>{`: The public key is used for verifying signatures.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`enc`}</strong>{`: The public key is used for encrypting data.`}</p>
      </li>
    </ul>
    <p>{`Other values may be used but the generated JWKs will not work for signing or
encryption with this tool.`}</p>
    <p><strong parentName="p">{`--kid`}</strong>{`=`}<inlineCode parentName="p">{`kid`}</inlineCode>{`
The `}<inlineCode parentName="p">{`kid`}</inlineCode>{` (key ID) for this JWK. Corresponds to the
"kid" JWK parameter. Used to identify an individual key in a JWK Set, for
example. `}<inlineCode parentName="p">{`kid`}</inlineCode>{` is a case-sensitive string. If unset, the JWK Thumbprint
`}{`[RFC7638]`}{` is used as `}<inlineCode parentName="p">{`kid`}</inlineCode>{`. See `}<strong parentName="p">{`step help crypto jwk thumbprint`}</strong>{` for more
information on JWK Thumbprints.`}</p>
    <p><strong parentName="p">{`--from-pem`}</strong>{`=`}<inlineCode parentName="p">{`pem-file`}</inlineCode>{`
Create a JWK representing the key encoded in an
existing `}<inlineCode parentName="p">{`pem-file`}</inlineCode>{` instead of creating a new key.`}</p>
    <p><strong parentName="p">{`--password-file`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The path to the `}<inlineCode parentName="p">{`file`}</inlineCode>{` containing the password to encrypt or decrypt the private key.`}</p>
    <p><strong parentName="p">{`--no-password`}</strong>{`
Do not ask for a password to encrypt a private key. Sensitive key material will
be written to disk unencrypted. This is not recommended. Requires `}<strong parentName="p">{`--insecure`}</strong>{` flag.`}</p>
    <p><strong parentName="p">{`--subtle`}</strong></p>
    <p><strong parentName="p">{`--insecure`}</strong></p>
    <p><strong parentName="p">{`-f`}</strong>{`, `}<strong parentName="p">{`--force`}</strong>{`
Force the overwrite of files without asking.`}</p>
    <h2>{`Exit codes`}</h2>
    <p>{`This command returns 0 on success and >0 if any error occurs.`}</p>
    <h2>{`Security considerations`}</h2>
    <p>{`All security considerations from `}<strong parentName="p">{`step help crypto`}</strong>{` are relevant here.`}</p>
    <p><strong parentName="p">{`Preventing hostile disclosure of non-public key material`}</strong></p>
    <p>{`It is critical that any private and symmetric key material be protected from
unauthorized disclosure or modification. This includes the private key for
asymmetric key types (RSA, EC, and OKP) and the shared secret for symmetric key
types (oct). One means of protection is encryption. Keys can also be stored in
hardware or software "security enclaves" such as HSMs and TPMs or operating
system keychain management tools.`}</p>
    <p><strong parentName="p">{`Key provenance and bindings`}</strong></p>
    <p>{`Key provenance should always be scrutinized. You should not trust a key that
was obtained in an untrustworthy manner (e.g., non-TLS HTTP).`}</p>
    <p>{`Usually applications use keys to make authorization decisions based on
attributes "bound" to the key such as the key owner's name or role. In these
scenarios the strength of the system's security depends on the strength of
these "bindings". There are a variety of mechanisms for securely binding
attributes to keys, including:`}</p>
    <ul>
      <li parentName="ul">{`Cryptographically binding attributes to the public key using x509
certificates (e.g., as defined in PKIX / RFC2580)`}</li>
      <li parentName="ul">{`Cryptographically binding attributes to the public key using JWTs`}</li>
      <li parentName="ul">{`Storing the public key or (hashed) shared secret along with the bound
attributes in a secure database`}</li>
    </ul>
    <p>{`Cryptographic mechanisms require establishing a "root of trust" that can sign
the bindings (the certificates or JWTs) asserting that the bound attributes are
correct.`}</p>
    <h2>{`Standards`}</h2>
    <p>{`[RFC7517]`}{`
Jones, M., "JSON Web Key (JWK)", `}<a parentName="p" {...{
        "href": "https://tools.ietf.org/html/rfc7517"
      }}>{`https://tools.ietf.org/html/rfc7517`}</a></p>
    <p>{`[RFC7518]`}{`
Jones, M., "JSON Web Algorithms (JWA)", `}<a parentName="p" {...{
        "href": "https://tools.ietf.org/html/rfc7518"
      }}>{`https://tools.ietf.org/html/rfc7518`}</a></p>
    <p>{`[RFC7638]`}{`
M. Jones, N. Sakimura., "JSON Web Key (JWK) Thumbprint",
`}<a parentName="p" {...{
        "href": "https://tools.ietf.org/html/rfc7638"
      }}>{`https://tools.ietf.org/html/rfc7638`}</a></p>
    <p>{`[RFC8037]`}{`
I. Liusvaara., "CFRG Elliptic Curve Diffie-Hellman (ECDH) and Signatures in
JSON Object Signing and Encryption (JOSE)",
`}<a parentName="p" {...{
        "href": "https://tools.ietf.org/html/rfc8037"
      }}>{`https://tools.ietf.org/html/rfc8037`}</a></p>
    <h2>{`Examples`}</h2>
    <p>{`Create a new JWK using default options:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto jwk create jwk.pub.json jwk.json
`}</code></pre>
    <p>{`Create an RSA JWK:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto jwk create rsa.pub.json rsa.json --kty RSA
`}</code></pre>
    <p>{`Create a symmetric key (oct key type):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto jwk create oct.pub.json oct.json --kty oct
`}</code></pre>
    <p>{`Create a key for use with the Ed25519 cryptosystem:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto jwk create ed.pub.json ed.json \\
    --kty OKP --crv Ed25519
`}</code></pre>
    <p>{`Create a key from an existing PEM file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto jwk create jwk.pub.json jwk.json \\
    --from-pem key.pem
`}</code></pre>
    <p>{`Create an 4096 bit RSA encryption key:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto jwk create rsa-enc.pub.json rsa-enc.json \\
   --kty RSA --size 4096 --use enc
`}</code></pre>
    <p>{`Create a 192 bit symmetric encryption key for use with AES Key Wrap:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto jwk create kw.pub.json kw.json \\
    --kty oct --size 192 --use enc --alg A192GCMKW
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      